import React from 'react';
import { Grid } from '@material-ui/core';
import { Alert as AlertUI, AlertTitle } from '@material-ui/lab';

export default function Alert(props) {
	return (
		<Grid container direction="row" justify="center" alignItems="center" style={{ padding: 20 }}>
			<AlertUI severity={props.type} style={{ justifyContent: 'center' }}>
				<AlertTitle>{props.title}</AlertTitle>
				<b>{props.message}</b>
			</AlertUI>
		</Grid>
	);
}
