import React from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";

// import CSS files
import "@fullcalendar/core/main.css";
import "@fullcalendar/daygrid/main.css";

export default class Falendar extends React.Component {
  render() {
    return (
      <FullCalendar
        defaultView="dayGridMonth"
        plugins={[dayGridPlugin]}
        locale="fr"
        header={{
          left: "title",
          right: "prev,next",
        }}
        events={this.props.events}
      />
    );
  }
}
