import React from "react";
import {
  CssBaseline,
  Box,
  Typography,
  Container,
  Link as A,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import { Copyright } from "../layouts";
import { withStyles } from "@material-ui/core/styles";
import MoodBadIcon from "@material-ui/icons/MoodBad";

const useStyles = (theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    color: theme.palette.secondary.main,
    backgroundColor: theme.palette.common.white,
  },
  form: {
    width: "100%",
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
});

class NotFoundComponent extends React.Component {
  render() {
    const { classes } = this.props;
    return (
      <Container component="main" maxWidth="md">
        <CssBaseline />
        <div className={classes.paper}>
          <MoodBadIcon style={{ fontSize: 80, color: "#2196f3" }} />
          <Typography component="h1" variant="h4" style={{ marginBottom: 15 }}>
            404 &sdot; Page introuvable
          </Typography>
          Oops, la page que vous avez demandé n'existe pas. Rendez-vous sur la
          page d'accueil du site.
          <A to="/account" component={Link} style={{ marginTop: 20 }}>
            Retourner à la page d'accueil
          </A>
        </div>
        <Box mt={8}>
          <Copyright />
        </Box>
      </Container>
    );
  }
}

export default withStyles(useStyles)(NotFoundComponent);
