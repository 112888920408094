import React from 'react';

import {
	Avatar,
	Button,
	CssBaseline,
	TextField,
	FormControlLabel,
	Checkbox,
	Link,
	Grid,
	Box,
	Typography,
	Container,
	CircularProgress,
} from '@material-ui/core';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import { Copyright } from '../layouts';
import { userService } from '../services';
import { Alert, AlertTitle } from '@material-ui/lab';
import { withStyles } from '@material-ui/core/styles';

const useStyles = (theme) => ({
	paper: {
		marginTop: theme.spacing(8),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	avatar: {
		margin: theme.spacing(1),
		backgroundColor: theme.palette.primary.main,
	},
	form: {
		width: '100%',
		marginTop: theme.spacing(1),
	},
	submit: {
		margin: theme.spacing(3, 0, 1),
	},
	guest: {
		margin: theme.spacing(0, 0, 2),
	},
});

class LoginComponent extends React.Component {
	constructor(props) {
		super(props);
		userService.logout();
		this.state = {
			email: '',
			password: '',
			submitted: false,
			loading: false,
			loading_guest: false,
			error: '',
		};
		this.handleChange = this.handleChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.handleGuest = this.handleGuest.bind(this);
	}

	validateForm() {
		return this.state.email.length > 0 && this.state.password.length > 0;
	}

	handleChange(e) {
		const { name, value } = e.target;
		this.setState({ [name]: value });
	}

	handleSubmit(e) {
		e.preventDefault();

		this.setState({ submitted: true });
		const { email, password } = this.state;

		if (!(email && password)) return;

		this.setState({ loading: true });
		userService.login(email, password).then(
			(user) => {
				const { from } = this.props.location.state || {
					from: { pathname: '/account' },
				};
				this.props.history.push(from);
			},
			(error) => this.setState({ error, loading: false })
		);
	}

	handleGuest() {
		console.log('Handle guest connexion');
		this.setState({ loading_guest: true });
		userService.login('guest@__guest__.fr', '#').then(
			(user) => {
				const { from } = this.props.location.state || {
					from: { pathname: '/account' },
				};
				this.props.history.push(from);
			},
			(error) => this.setState({ error, loading: false })
		);
	}

	render() {
		const { classes } = this.props;
		const { email, error, loading, loading_guest } = this.state;
		return (
			<Container component="main" maxWidth="xs">
				<CssBaseline />
				<div className={classes.paper}>
					<Avatar className={classes.avatar}>
						<LockOutlinedIcon />
					</Avatar>
					<Typography component="h1" variant="h5">
						Connexion
					</Typography>
					{error && (
						<Alert severity="error" style={{ justifyContent: 'center' }}>
							<AlertTitle>Oops!</AlertTitle>
							<strong>{error.message}</strong>
						</Alert>
					)}
					<form className={classes.form} noValidate method="POST" onSubmit={this.handleSubmit}>
						<TextField
							variant="outlined"
							margin="normal"
							required
							fullWidth
							label="Adresse e-mail"
							type="email"
							id="email"
							name="email"
							autoComplete="email"
							autoFocus
							value={email}
							onChange={this.handleChange}
						/>
						<TextField
							variant="outlined"
							margin="normal"
							required
							fullWidth
							name="password"
							label="Mot de passe"
							type="password"
							id="password"
							autoComplete="current-password"
							onChange={this.handleChange}
						/>
						<FormControlLabel
							control={<Checkbox value="remember" color="secondary" />}
							label="Se souvenir de moi"
						/>
						<Button
							type="submit"
							fullWidth
							variant="contained"
							color="secondary"
							className={classes.submit}
							disabled={!this.validateForm()}
						>
							{!loading ? 'Se connecter' : <CircularProgress size={28} style={{ color: 'white' }} />}
						</Button>
						<Button
							type="button"
							fullWidth
							variant="contained"
							color="primary"
							className={classes.guest}
							onClick={this.handleGuest}
						>
							{!loading_guest ? 'Se connecter en tant qu\'invité' : <CircularProgress size={28} style={{ color: 'white' }} />}
						</Button>
						<Grid container>
							<Grid item xs={6}>
								<Link href="/resetting" variant="body2">
									Mot de passe oublié?
								</Link>
							</Grid>
							<Grid item xs={6}>
								<Link href="/registration" variant="body2">
									{"Vous n'avez pas de compte ? Contactez-nous"}
								</Link>
							</Grid>
						</Grid>
					</form>
				</div>
				<Box mt={8}>
					<Copyright />
				</Box>
			</Container>
		);
	}
}

export default withStyles(useStyles)(LoginComponent);
