import React from 'react';

import {
	CssBaseline,
	Link,
	Grid,
	Box,
	Container,
	Avatar,
	Typography,
	CircularProgress,
} from '@material-ui/core';
import { Copyright } from '../layouts';
import { userService } from '../services';
import { withStyles } from '@material-ui/core/styles';
import DOMPurify from 'dompurify';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';

const useStyles = (theme) => ({
	paper: {
		marginTop: theme.spacing(8),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	avatar: {
		margin: theme.spacing(1),
		backgroundColor: theme.palette.secondary.main,
	},
	form: {
		width: '100%',
		marginTop: theme.spacing(1),
	},
	submit: {
		margin: theme.spacing(3, 0, 2),
	},
});

class RegistrationComponent extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			page: '',
			loading: true
		};
	}

	componentDidMount() {
		userService.registration().then(
			(response) => {
				this.setState({ page: response.page, loading: false });
			},
			(error) => {
				this.setState({error: error, loading: false });
			}
		);
	}

	render() {
		const { classes } = this.props;
		const {page, loading} = this.state;
		return (
			<Container component="main" maxWidth="xs">
				<CssBaseline />
				<div className={classes.paper}>
					<Avatar className={classes.avatar}>
						<LockOutlinedIcon />
					</Avatar>
					<Typography component="h1" variant="h5">
						Inscription au portail adhérents
					</Typography>
					<div className="content" dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(page)}}></div>
					{!loading ? '' : <CircularProgress size={48} style={{ margin: 100 }} />}
					<Grid container>
						<Grid item xs>
							<Link href="/" variant="body2">
								Retourner au formulaire de connexion
							</Link>
						</Grid>
					</Grid>
				</div>
				<Box mt={8}>
					<Copyright />
				</Box>
			</Container>
		);
	}
}

export default withStyles(useStyles)(RegistrationComponent);
