import React from 'react';
import { filterService, catalogService } from '../services';
import { Title, Alert, MediaCard } from '../layouts';
import { Grid, CircularProgress, TextField, Button } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import RefreshIcon from '@material-ui/icons/Refresh';
import Filter from '../layouts/Filter';
import FilterListIcon from '@material-ui/icons/FilterList';

class CatalogComponent extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			error: null,
			items: [],
			filters: [],
			isLoaded: false,
			searchValue: '',
			isNoData: false,
			offset: 0,
			prevY: 0,
			isFiltred: false
		};
		this.handleChange = this.handleChange.bind(this);
		this.handleSearch = this.handleSearch.bind(this);
		this.handleReset = this.handleReset.bind(this);
		this.handleAdvancedSearch = this.handleAdvancedSearch.bind(this);
		this.handleFilters = this.handleFilters.bind(this);
		this.keyPress = this.keyPress.bind(this);
	}

	getFilters() {
		filterService.get().then(
			(res) => {
				this.setState({ filters: res.filters });
				this.setState({ isLoaded: true });
			},
			(error) => {
				this.setState({ error: error });
				this.setState({ isLoaded: true });
			}
		);
	}

	getGames(offset) {
		this.setState({ isLoaded: false });
		catalogService.getAll(offset).then(
			(res) => {
				this.setState({ items: [...this.state.items, ...res.items] });
				this.setState({ isLoaded: true });
			},
			(error) => {
				this.setState({ isLoaded: true, error: error });
			}
		);
	}

	componentDidMount() {
		this.getGames(this.state.offset);
		this.observer = new IntersectionObserver(this.handleObserver.bind(this));
		this.observer.observe(this.loadingRef);
	}
	
	handleObserver(entities) {
		const y = entities[0].boundingClientRect.y;
		if (this.state.prevY > y && this.state.isLoaded) {
			if (this.state.isFiltred == false) {
				const offset = this.state.items.length;
				this.getGames(offset);
				this.setState({ offset: offset });
			}
		}
		this.setState({ prevY: y });
	}

	validateForm() {
		return this.state.searchValue.length > 0;
	}

	handleChange(e) {
		const { name, value } = e.target;
		this.setState({ [name]: value });
	}

	handleSearch() {
		this.setState({ items: [], isLoaded: false, isNoData: false, isFiltred: true });
		catalogService.getByValue(this.state.searchValue).then(
			(res) => {
				this.setState({ items: res.items, isLoaded: true });
				if (res.items.length === 0) {
					this.setState({ isNoData: true });
				}
			},
			(error) => {
				this.setState({ isLoaded: true, error: error });
			}
		);
	}

	handleAdvancedSearch(filters) {
		this.setState({ items: [], isLoaded: false, isNoData: false });
		catalogService.getByFilters(filters).then(
			(res) => {
				this.setState({ items: res.items, isLoaded: true });
				if (res.items.length === 0) {
					this.setState({ isNoData: true });
				}
			},
			(error) => {
				this.setState({ isLoaded: true, error: error });
			}
		);
	}

	handleReset() {
		this.setState({
			searchValue: '',
			isNoData: false,
			items: [],
			filters: [],
			isLoaded: false,
			offset: 0,
			prevY: 0,
			isFiltred: false
		});
		this.getGames(this.state.offset);
		this.observer = new IntersectionObserver(this.handleObserver.bind(this));
		this.observer.observe(this.loadingRef);
	}

	handleFilters() {
		this.setState({
			searchValue: '',
			isNoData: false,
			items: [],
			filters: [],
			isLoaded: false,
			offset: 0,
			prevY: 0,
			isFiltred: true
		});
		this.getFilters();
	}

	keyPress(e){
		if(e.keyCode == 13){
		   this.handleSearch();
		}
	 }

	render() {
		const { error, items, filters, isLoaded, searchValue, isNoData } = this.state;
		return (
			<React.Fragment>
				<Grid container>
					<Title>Catalogue de jeux</Title>
				</Grid>
				{error && (
					<Alert
						type="error"
						title="Oops!"
						message="Une erreur est survenue pendant la récupération des éléments à afficher."
					/>
				)}
				<Grid container style={{ textAlign: 'center' }}>
					<Grid item xs={12} style={{ textAlign: 'center' }}>
						<TextField
							id="searchValue"
							label="Rechercher un jeu"
							style={{ margin: 8, width: '80%' }}
							type="text"
							name="searchValue"
							margin="normal"
							variant="outlined"
							value={searchValue}
							onChange={this.handleChange}
							onKeyDown={this.keyPress}
						/>
					</Grid>
					<Grid item xs={12} style={{ textAlign: 'center' }}>
						<Button
							variant="contained"
							color="secondary"
							disabled={!this.validateForm()}
							onClick={this.handleSearch}
							style={{ marginRight: '10px' }}
						>
							<SearchIcon /> Recherche par mot clé
						</Button>
						<Button
							variant="contained"
							color="secondary"
							onClick={this.handleReset}
							style={{ marginRight: '10px' }}
						>
							<RefreshIcon /> Réinitialiser
						</Button>
						<Button
							variant="contained"
							color="secondary"
							onClick={this.handleFilters}
						>
							<FilterListIcon /> Recherche avancée
						</Button>
					</Grid>
				</Grid>
				{Array.isArray(filters) && filters.length > 0 && <Grid item xs={12} md={12} lg={12} style={{ textAlign: 'center' }}>
					<Filter filters={filters} onFilter={this.handleAdvancedSearch}></Filter>
				</Grid>}
				{isNoData && <Alert type="info" title="Oops!" message="Votre recherche ne correspond à aucun jeu." />}
				{items.map((item) => (
					<Grid key={item._id} item xs={12} sm={6} md={4} lg={4} xl={3}>
						<MediaCard item={item} btnText="Réserver" />
					</Grid>
				))}
				{!isLoaded && (
					<Grid
						container
						spacing={0}
						direction="column"
						alignItems="center"
						justify="center"
						style={{ minHeight: '10vh' }}
					>
						<CircularProgress size={40} style={{ margin: 10 }} />
					</Grid>
				)}
				<div ref={(loadingRef) => (this.loadingRef = loadingRef)}></div>
			</React.Fragment>
		);
	}
}

export default CatalogComponent;
