import React from 'react';
import { Grid, Button, Box, CircularProgress, Paper, TextField } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { Title, Alert, Calendar } from '../layouts';
import { bookingService } from '../services';

import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';

import fr from "date-fns/locale/fr";

class BookingsFormComponent extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			dateStart: '',
			dateEnd: '',
			error: null,
			success: false,
			isLoaded: false,
			sendingBtn: false,
			events: false,
			loadingCalendar: true,
			game: props.match.params.id,
			dates: []
		};
		this.disabledDates = this.disabledDates.bind(this);
		this.handleDateStartChange = this.handleDateStartChange.bind(this);
		this.handleDateEndChange = this.handleDateEndChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
	}

	componentDidMount() {
		bookingService.getDateRange(this.state.game).then(
			(res) => {
				this.setState({ dateStart: res.dateStart, dateEnd: res.dateEnd, isLoaded: true, dates: res.disabledDates });
				if (res.disabledDates.length != 0) {
					this.setState({ dateStart: '', dateEnd: '' });
				}
			},
			(error) => {
				this.setState({ isLoaded: true, error: error });
			}
		);

		bookingService.getCalendar(this.state.game).then((res) => {
			this.setState({ events: res.events, loadingCalendar: false });
		});
	}

	handleDateStartChange(date) {
		date = date.toISOString().slice(0,10).replace(/-/g,"-");
		this.setState({ dateStart: date });
	}

	handleDateEndChange(date) {
		date = date.toISOString().slice(0,10).replace(/-/g,"-");
		this.setState({ dateEnd: date });
	}

	validateForm() {
		return this.state.dateStart.length > 0 && this.state.dateEnd.length > 0;
	}

	handleSubmit(e) {
		e.preventDefault();
		this.setState({ isLoaded: false });
		const { dateStart, dateEnd, game } = this.state;

		if (!(dateStart && dateEnd && game)) 
			return;

		this.setState({ isLoaded: true, sendingBtn: true });
		bookingService.create(dateStart, dateEnd, game).then(
			(result) => {
				this.setState({ success: true, isLoaded: true, sendingBtn: false });
			},
			(error) => {
				this.setState({ error: true, isLoaded: true, sendingBtn: false });
			}
		);
	}

	disabledDates(date) {
		let dates = this.state.dates,
			currentDate = date.toISOString().slice(0,10).replace(/-/g,"-");
		return dates.indexOf(currentDate) > -1;
	}
	
	render() {
		const { dateStart, dateEnd, success, error, isLoaded, events, loadingCalendar, sendingBtn } = this.state;

		return (
			<React.Fragment>
				{error && (
					<Alert
						type="error"
						title="Oops!"
						message="Une erreur est survenue durant l'enregistrement de votre réservation, veuillez remplir tout les champs et réessayer."
					/>
				)}
				{success && (
					<Alert
						type="success"
						title="Merci!"
						message="Votre demande de réservation a bien été enregistrée."
					/>
				)}
				<Title>Nouvelle réservation</Title>
				<Grid
					container
					direction="row"
					justify="center"
					alignItems="center"
					component={Paper}
					style={{ padding: 20 }}
				>
					{!isLoaded && (
						<Grid
							container
							spacing={0}
							direction="column"
							alignItems="center"
							justify="center"
							style={{ minHeight: '10vh' }}
						>
							<CircularProgress size={40} style={{ margin: 10 }} />
						</Grid>
					)}
					{isLoaded && (
						<form noValidate autoComplete="off" onSubmit={this.handleSubmit}>
							<Box width={1}>
								<MuiPickersUtilsProvider utils={DateFnsUtils} locale={fr}>
									<Grid container justify="space-around">
										<KeyboardDatePicker
											disableToolbar
											variant="inline"
											format="dd/MM/yyyy"
											margin="normal"
											id="dateStart"
											label="Date de début de réservation"
											helperText="Veuillez entrer une date de début correcte."
											value={dateStart}
											onChange={this.handleDateStartChange}
											shouldDisableDate={this.disabledDates}
											KeyboardButtonProps={{
												'aria-label': 'Changer la date',
											}}
										/>
										<KeyboardDatePicker
											disableToolbar
											variant="inline"
											format="dd/MM/yyyy"
											margin="normal"
											id="dateEnd"
											label="Date de fin de réservation"
											helperText="Veuillez entrer une date de fin correcte."
											value={dateEnd}
											onChange={this.handleDateEndChange}
											shouldDisableDate={this.disabledDates}
											KeyboardButtonProps={{
												'aria-label': 'Changer la date',
											}}
										/>
									</Grid>
								</MuiPickersUtilsProvider>
							</Box>
							<Box width={1}>
								<Button
									type="submit"
									variant="contained"
									color="secondary"
									disabled={!this.validateForm() || success}
								>
									{!sendingBtn ? (
										'Enregistrer'
									) : (
										<CircularProgress size={28} style={{ color: 'white' }} />
									)}
								</Button>
								<Button
									variant="outlined"
									to="/account"
									component={Link}
									title="Retourner"
									style={{ marginLeft: 5 }}
								>
									Retourner au catalogue
								</Button>
							</Box>
						</form>
					)}
				</Grid>
				<Grid container component={Paper} style={{ marginTop: 10, padding: 20 }}>
					{!loadingCalendar && <Calendar events={events} />}
					{loadingCalendar && (
						<Grid
							container
							spacing={0}
							direction="column"
							alignItems="center"
							justify="center"
							style={{ minHeight: '10vh' }}
						>
							<CircularProgress size={40} style={{ margin: 10 }} />
						</Grid>
					)}
				</Grid>
			</React.Fragment>
		);
	}
}

export default BookingsFormComponent;
