import { authToken, env } from '../helper';
import { userService } from '.';

export const bookingService = {
	getAll,
	create,
	getDateRange,
	getCalendar,
	cancel,
};

async function getAll() {
	const response = await fetch(`${env.API_URL}/api/reservations/`, {
		method: 'GET',
		headers: authToken(),
	}).then((response) => {
		if (!response.ok) {
			userService.logout();
			window.location.reload(true);
		}
		return response;
	});
	return await response.json();
}

async function getDateRange(game) {
	const response = await fetch(`${env.API_URL}/api/reservations/plage_dates?q=${game}`, {
		method: 'GET',
		headers: authToken(),
	}).then((response) => {
		if (!response.ok) {
			userService.logout();
			window.location.reload(true);
		}
		return response;
	});
	return await response.json();
}

async function create(dateStart, dateEnd, game) {
	let headers = authToken();
	headers['Content-Type'] = 'application/json';

	const response = await fetch(`${env.API_URL}/api/reservations/creer`, {
		method: 'POST',
		headers: headers,
		body: JSON.stringify({
			dateStart: dateStart,
			dateEnd: dateEnd,
			game: game,
		}),
	}).then((response) => {
		if (!response.ok) {
			userService.logout();
			window.location.reload(true);
		}
		return response;
	});
	return await response.json();
}

async function cancel(booking) {
	const response = await fetch(`${env.API_URL}/api/reservations/annuler?q=${booking}`, {
		method: 'GET',
		headers: authToken(),
	}).then((response) => {
		if (!response.ok) {
			userService.logout();
			window.location.reload(true);
		}
		return response;
	});
	return await response.json();
}

async function getCalendar(game) {
	const response = await fetch(`${env.API_URL}/api/reservations/calendar?q=${game}`, {
		method: 'GET',
		headers: authToken(),
	}).then((response) => {
		if (!response.ok) {
			userService.logout();
			window.location.reload(true);
		}
		return response;
	});
	return await response.json();
}
