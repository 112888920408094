import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';

const styles = (theme) => ({
	root: {
		margin: 0,
		padding: theme.spacing(2),
	},
	closeButton: {
		position: 'absolute',
		right: theme.spacing(1),
		top: theme.spacing(1),
		color: theme.palette.grey[500],
	},
});

const DialogTitle = withStyles(styles)((props) => {
	const { children, classes, onClose, ...other } = props;
	return (
		<MuiDialogTitle disableTypography className={classes.root} {...other}>
			<Typography variant="h6">{children}</Typography>
			{onClose ? (
				<IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
					<CloseIcon />
				</IconButton>
			) : null}
		</MuiDialogTitle>
	);
});

const DialogContent = withStyles((theme) => ({
	root: {
		padding: theme.spacing(2),
	},
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
	root: {
		margin: 0,
		padding: theme.spacing(1),
	},
}))(MuiDialogActions);

export default function AlertDialog(props) {
	const [open, setOpen] = React.useState(false);
	const [loading, setLoading] = React.useState(false);

	React.useEffect(() => {
		setOpen(props.open);
		setLoading(false);
	}, [props]);

	const handleConfirm = () => {
		setLoading(true);
		props.callback();
	};
	const handleClose = () => {
		setOpen(false);
	};

	return (
		<div>
			<Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
				<DialogTitle id="customized-dialog-title" onClose={handleClose}>
					{props.title}
				</DialogTitle>
				<DialogContent dividers>
					{loading && (
						<Grid
							container
							spacing={0}
							direction="column"
							alignItems="center"
							justify="center"
							style={{ minHeight: '10vh' }}
						>
							<CircularProgress size={40} style={{ margin: 10 }} />
						</Grid>
					)}
					<Typography gutterBottom>{props.desc}</Typography>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose} color="default">
						Annuler
					</Button>
					<Button onClick={handleConfirm} color="primary">
						Oui, je confirme
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
}
