import React from 'react';
import {
	Grid,
	CircularProgress,
	Paper,
	Table,
	TableCell,
	TableRow,
	TableBody,
	withStyles,
	TextField,
	Button,
	Select,
	MenuItem
} from '@material-ui/core';

import { Title, Alert } from '../layouts';
import { userService } from '../services';

class UserComponent extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			data: null,
			cities: null,
			ville: {
				id: null,
				name: null,
				zipcode: null,
			},
			error: null,
			isLoaded: false,
		};
		this.handleChange = this.handleChange.bind(this);
		this.handleChangeSelect = this.handleChangeSelect.bind(this);
		this.handleSave = this.handleSave.bind(this);
	}

	componentWillMount() {
		this.setState({ isLoaded: true });
		userService.getUser().then(
			(result) => {
				this.setState({ data: result.user, cities: result.cities, ville: result.user.ville, isLoaded: false });
			},
			(error) => {
				this.setState({ error: true, isLoaded: false });
			}
		);
	}

	handleChange(e) {
		const { name, value } = e.target;
		let data = this.state.data;
		data[name] = value ? value : '';
		this.setState({ data: data });
	}

	handleChangeSelect(e) {
		let id = e.target.value,
			cities = this.state.cities,
			city = cities.filter(city => city.id === id).map(city => {
				return {
					id: city.id,
					name: city.name,
					zipcode: city.zipcode,
				};
			});
		if(city)
			this.setState({ ville: city[0] });
	}

	validateForm() {
		return this.state.data.email.length > 0;
	}

	handleSave(e) {
		e.preventDefault();
		if (this.state.data.email) {
			this.setState({ data: null, cities: null, ville: null, isLoaded: true });
			userService.profil({
				nom: this.state.data.nom ?? '',
				prenom: this.state.data.prenom ?? '',
				email: this.state.data.email ?? '',
				responsable: this.state.data.responsable ?? '',
				adresse: this.state.data.adresse ?? '',
				portable: this.state.data.portable ?? '',
				telephone: this.state.data.telephone ?? '',
				ville: this.state.ville.id ?? ''
			}).then(
				(result) => {
					this.setState({ data: result.user, cities: result.cities, ville: result.user.ville, isLoaded: false });
				},
				(error) => {
					this.setState({ error: true, isLoaded: false });
				}
			);
		}
	}

	render() {
		const { data, ville, cities, error, isLoaded } = this.state;
		const HeadTableCell = withStyles((theme) => ({
			body: {
				fontWeight: 'bold',
				width: '20%',
			},
		}))(TableCell);

		return (
			<Grid style={{ width: '100%' }}>
				{error && (
					<Alert
						type="error"
						title="Oops!"
						message="Une erreur est survenue durant la récupération des données, veuillez réessayer ultérieurement."
					/>
				)}
				<Title>Fiche adhérent</Title>
				<Grid container component={Paper} style={{ padding: 20 }}>
					{data && (
						<Table size="small">
							<TableBody>
								<TableRow>
									<HeadTableCell>Code adhérent:</HeadTableCell>
									<TableCell>{data.code}</TableCell>
								</TableRow>
								<TableRow>
									<HeadTableCell>Type d'adhérent:</HeadTableCell>
									<TableCell>
										<TextField size="small" variant="outlined" defaultValue={data.type} disabled />
									</TableCell>
								</TableRow>
								<TableRow>
									<HeadTableCell>Nom:</HeadTableCell>
									<TableCell>
										<TextField name="nom" size="small" variant="outlined" defaultValue={data.nom} onChange={this.handleChange} />
									</TableCell>
								</TableRow>
								<TableRow>
									<HeadTableCell>Prénom:</HeadTableCell>
									<TableCell>
										<TextField name="prenom" size="small" variant="outlined" defaultValue={data.prenom} onChange={this.handleChange} />
									</TableCell>
								</TableRow>
								<TableRow>
									<HeadTableCell>Nom du responsable:</HeadTableCell>
									<TableCell>
										<TextField
											name="responsable"
											size="small"
											variant="outlined"
											defaultValue={data.responsable}
											onChange={this.handleChange}
										/>
									</TableCell>
								</TableRow>
								<TableRow>
									<HeadTableCell>E-mail:</HeadTableCell>
									<TableCell>
										<TextField
											name="email"
											size="small"
											variant="outlined"
											defaultValue={data.email}
											onChange={this.handleChange}
										/>
									</TableCell>
								</TableRow>
								<TableRow>
									<HeadTableCell>Téléphone:</HeadTableCell>
									<TableCell>
										<TextField
											name="telephone"
											size="small"
											variant="outlined"
											defaultValue={data.telephone}
											onChange={this.handleChange}
										/>
									</TableCell>
								</TableRow>
								<TableRow>
									<HeadTableCell>Portable:</HeadTableCell>
									<TableCell>
										<TextField
											name="portable"
											size="small"
											variant="outlined"
											defaultValue={data.portable}
											onChange={this.handleChange}
										/>
									</TableCell>
								</TableRow>
								<TableRow>
									<HeadTableCell>Adresse:</HeadTableCell>
									<TableCell>
										<TextField
											name="adresse"
											size="small"
											variant="outlined"
											defaultValue={data.adresse}
											onChange={this.handleChange}
										/>
									</TableCell>
								</TableRow>
								<TableRow>
									<HeadTableCell>Ville:</HeadTableCell>
									<TableCell>
										<Select
											size="small"
											variant="outlined"
											id="ville"
											value={ville.id}
											onChange={this.handleChangeSelect}
										>
											{(cities || []).map(item => {
												return (
													<MenuItem key={item.id} value={item.id}>{item.name} ({item.zipcode})</MenuItem>
												);
											})}
										</Select>
									</TableCell>
								</TableRow>
								<TableRow>
									<HeadTableCell>Code postal:</HeadTableCell>
									<TableCell>
										<TextField
											size="small"
											variant="outlined"
											value={ville.zipcode}
											helperText="Le code postal change en fonction de la ville sélectionnée."
											disabled
										/>
									</TableCell>
								</TableRow>
								{/* <TableRow>
									<HeadTableCell>Secteur:</HeadTableCell>
									<TableCell>
										<TextField
											size="small"
											variant="outlined"
											defaultValue={data.secteur}
											disabled
										/>
									</TableCell>
								</TableRow>
								<TableRow>
									<HeadTableCell>Quartier:</HeadTableCell>
									<TableCell>
										<TextField
											size="small"
											variant="outlined"
											defaultValue={data.quartier}
											disabled
										/>
									</TableCell>
								</TableRow> */}
								<TableRow>
									<HeadTableCell>Structure:</HeadTableCell>
									<TableCell>
										<TextField
											size="small"
											variant="outlined"
											defaultValue={data.structure}
											disabled
										/>
									</TableCell>
								</TableRow>
								<TableRow>
									<HeadTableCell>Date d'inscription:</HeadTableCell>
									<TableCell>
										<TextField size="small" variant="outlined" defaultValue={data.date} disabled />
									</TableCell>
								</TableRow>
								{
									<TableRow>
										<HeadTableCell></HeadTableCell>
										<TableCell align="right">
											<Button
												variant="contained"
												color="primary"
												onClick={this.handleSave}
												disabled={!this.validateForm()}
											>
												Enregistrer
											</Button>
										</TableCell>
									</TableRow>
								}
							</TableBody>
						</Table>
					)}
					{isLoaded && (
						<Grid
							container
							spacing={0}
							direction="column"
							alignItems="center"
							justify="center"
							style={{ minHeight: '10vh' }}
						>
							<CircularProgress size={40} style={{ margin: 10 }} />
						</Grid>
					)}
				</Grid>
			</Grid>
		);
	}
}

export default UserComponent;
