import { authToken, env } from '../helper';

export const userService = {
	login,
	logout,
	getUser,
	profil,
	resetting,
	registration
};

function login(email, password) {
	const options = {
		method: 'POST',
		headers: { 'Content-Type': 'application/json' },
		body: JSON.stringify({ email, password }),
	};
	return fetch(`${env.API_URL}/api/utilisateurs/login`, options)
		.then(handleResponse)
		.then((user) => {
			if (user) {
				user.authdata = window.btoa(email + ':' + password);
				user.is_guest = password == '#' ? true : false;
				localStorage.setItem('user', JSON.stringify(user));
				console.log('Connexion au portail ...');
			}
			return user;
		});
}

async function getUser() {
	const response = await fetch(`${env.API_URL}/api/utilisateurs/profil`, {
		method: 'GET',
		headers: authToken(),
	}).then((response) => {
		if (!response.ok) {
			userService.logout();
			window.location.reload(true);
		}
		return response;
	});
	return await response.json();
}

async function profil(data) {
	let headers = authToken();
	headers['Content-Type'] = 'application/json';
	const response = await fetch(`${env.API_URL}/api/utilisateurs/profil`, {
		method: 'POST',
		headers: headers,
		body: JSON.stringify(data),
	}).then((response) => {
		if (!response.ok) {
			console.log(response);
			//userService.logout();
			//window.location.reload(true);
		}
		return response;
	});
	return await response.json();
}

function logout() {
	localStorage.removeItem('user');
}

function handleResponse(response) {
	return response.text().then((text) => {
		const data = text && JSON.parse(text);
		if (!response.ok) {
			let message = 'Le service est temporairement indisponible.';
			if (response.status === 401) {
				message = "L'adresse e-mail ou le mot de passe saisi est incorrect.";
			}
			throw new Error(message);
		}
		return data;
	});
}

function resetting(email) {
	const options = {
		method: 'GET',
		headers: { 'Content-Type': 'application/json' },
	};
	return fetch(`${env.API_URL}/api/utilisateurs/resetting?email=${email}`, options)
		.then(handleResponse)
		.then((response) => {
			return response;
		});
}

function registration() {
	const options = {
		method: 'GET'
	};
	return fetch(`${env.API_URL}/api/utilisateurs/registration`, options)
		.then(handleResponse)
		.then((response) => {
			return response;
		});
}