import React from 'react';
import {
	CircularProgress,
	TableContainer,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	Paper,
	Grid,
	withStyles,
	Chip,
	Avatar,
	Typography,
	Button,
} from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import { bookingService } from '../services';
import { Title, Alert, AlertDialog } from '../layouts';

class BookingsComponent extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			error: null,
			items: [],
			isLoaded: false,
			openDialog: false,
			booking: null,
		};
		this.handleDialog = this.handleDialog.bind(this);
		this.handleCancel = this.handleCancel.bind(this);
	}

	componentDidMount() {
		bookingService.getAll().then(
			(result) => {
				this.setState({ isLoaded: true, items: result.items });
			},
			(error) => {
				this.setState({ isLoaded: true, error: error });
			}
		);
	}

	handleDialog(id) {
		this.setState({ openDialog: true, booking: id });
	}

	handleCancel() {
		bookingService.cancel(this.state.booking).then(
			(result) => {
				this.setState({ items: result.items, openDialog: false });
			},
			(error) => {
				this.setState({ isLoaded: true, error: error });
			}
		);
	}

	render() {
		const { error, items, isLoaded, openDialog } = this.state;

		const HeadTableCell = withStyles((theme) => ({
			head: {
				backgroundColor:  theme.palette.secondary ? theme.palette.secondary.main : '#e86a10',
				color: theme.palette.common.white,
			},
			body: {
				fontSize: 14,
			},
		}))(TableCell);

		return (
			<React.Fragment>
				<Title>Mes réservations</Title>
				{error && (
					<Alert
						type="error"
						title="Oops!"
						message="Une erreur est survenue pendant la récupération des éléments à afficher."
					/>
				)}
				<AlertDialog
					title="Annuler la réservation"
					desc="En cliquant sur je confirme, votre réservation sera annulée et le jeu sera à la disposition d'autres adhérents."
					open={openDialog}
					callback={this.handleCancel}
				/>
				<TableContainer component={Paper}>
					<Table size="small">
						<TableHead>
							<TableRow>
								<HeadTableCell>Type</HeadTableCell>
								<HeadTableCell>Jeu</HeadTableCell>
								<HeadTableCell>Date de début</HeadTableCell>
								<HeadTableCell>Date de fin</HeadTableCell>
								<HeadTableCell>État de la réservation</HeadTableCell>
								<HeadTableCell>Actions</HeadTableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{items.map((item) => (
								<TableRow hover key={item._id}>
									<TableCell>{item.type}</TableCell>
									<TableCell>
										<Typography color="textPrimary">{item.jeu.toLowerCase()}</Typography>
									</TableCell>
									<TableCell>{item.dateDebut}</TableCell>
									<TableCell>{item.dateFin}</TableCell>
									<TableCell>
										{item.annulation ? (
											<Chip label="Réservation annulée" color="primary" disabled="true" />
										) : item.retourner ? (
											<Chip label="Réservation terminée" color="primary" />
										) : (
											<Chip
												avatar={<Avatar>{item.retard}j</Avatar>}
												label={
													item.retard > 0 ? 'Réservation en retard' : 'Réservation en cours'
												}
												color={item.retard > 0 ? 'primary' : 'secondary'}
											/>
										)}
									</TableCell>
									<TableCell>
										<Button
											variant="outlined"
											color="secondary"
											onClick={() => this.handleDialog(item._id)}
											disabled={item.annulation}
										>
											<CancelIcon style={{ marginRight: 5 }} /> Annuler
										</Button>
									</TableCell>
								</TableRow>
							))}
						</TableBody>
					</Table>
					{!isLoaded && (
						<Grid
							container
							spacing={0}
							direction="column"
							alignItems="center"
							justify="center"
							style={{ minHeight: '10vh' }}
						>
							<CircularProgress size={40} style={{ margin: 10 }} />
						</Grid>
					)}
				</TableContainer>
			</React.Fragment>
		);
	}
}

export default BookingsComponent;
