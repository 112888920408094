import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import DashboardIcon from '@material-ui/icons/Dashboard';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import { Link } from 'react-router-dom';
import { checkPermissions } from '../helper';

export const MainNav = (
	<div>
		<ListItem button color="secondary" to="/account/bookings" component={Link} >
			<ListItemIcon>
				<DashboardIcon />
			</ListItemIcon>
			<ListItemText primary="Mes réservations" />
		</ListItem>
	</div>
);

export const SecondaryNav = (
	<div>
		<ListItem button color="secondary" to="/account/user" component={Link}>
			<ListItemIcon>
				<AccountBoxIcon />
			</ListItemIcon>
			<ListItemText primary="Fiche adhérent" />
		</ListItem>
	</div>
);
