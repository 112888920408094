import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core';
import { green, orange } from '@material-ui/core/colors';

const theme = createMuiTheme({
	typography: {
		fontFamily: `"Source Sans Pro", sans-serif`,
		fontSize: 15,
		fontWeightLight: 300,
		fontWeightRegular: 400,
		fontWeightMedium: 500,
	},
	palette: {
		primary: {
			main: localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')).configuration.ihm.color.primary : '#43B710'
		},
		secondary: {
			main: localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')).configuration.ihm.color.secondary : '#F45809'
		}
	},
});

ReactDOM.render(
	<MuiThemeProvider theme={theme}>
		<App />
	</MuiThemeProvider>,
	document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
