import { authToken, env } from '../helper';
import { userService } from '.';

export const filterService = {
    get,
};

async function get() {
    const response = await fetch(`${env.API_URL}/api/filters`, {
        method: 'GET',
        headers: authToken(),
    }).then((response) => {
        if (!response.ok) {
            userService.logout();
            window.location.reload(true);
        }
        return response;
    });
    return await response.json();
}