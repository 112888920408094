import React from 'react';

import {
	Avatar,
	Button,
	CssBaseline,
	TextField,
	FormControlLabel,
	Checkbox,
	Link,
	Grid,
	Box,
	Typography,
	Container,
	CircularProgress,
} from '@material-ui/core';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import { Copyright } from '../layouts';
import { userService } from '../services';
import { Alert, AlertTitle } from '@material-ui/lab';
import { withStyles } from '@material-ui/core/styles';

const useStyles = (theme) => ({
	paper: {
		marginTop: theme.spacing(8),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	avatar: {
		margin: theme.spacing(1),
		backgroundColor: theme.palette.secondary.main,
	},
	form: {
		width: '100%',
		marginTop: theme.spacing(1),
	},
	submit: {
		margin: theme.spacing(3, 0, 2),
	},
});

class ResettingComponent extends React.Component {
	constructor(props) {
		super(props);
		userService.logout();
		this.state = {
			email: '',
			submitted: false,
			loading: false,
			message: '',
			messageType: ''
		};
		this.handleChange = this.handleChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
	}

	validateForm() {
		return this.state.email.length;
	}

	handleChange(e) {
		const { name, value } = e.target;
		this.setState({ [name]: value });
	}

	handleSubmit(e) {
		e.preventDefault();

		this.setState({ submitted: true });
		const { email } = this.state;

		if (!(email))
			return;

		this.setState({ loading: true });
		userService.resetting(email).then(
			(response) => {
				this.setState({ message: response.message, messageType: response.type ,loading: false });
			},
			(error) => {
				console.log(error);
			}
		);
	}

	render() {
		const { classes } = this.props;
		const { email, message, messageType, loading } = this.state;
		console.log(messageType);
		return (
			<Container component="main" maxWidth="xs">
				<CssBaseline />
				<div className={classes.paper}>
					<Avatar className={classes.avatar}>
						<LockOutlinedIcon />
					</Avatar>
					<Typography component="h1" variant="h5">
						Demande d'identifiants portail
					</Typography>
					{!message && (
					<Alert severity="info" style={{ justifyContent: 'center', marginTop: 15 }}>
						<AlertTitle><b>Vous avez oublié votre mot de passe ?</b></AlertTitle>
						Entrez votre adresse e-mail ci-dessous et nous vous enverrons vos identifiants.<br/>
						Si vous ne voyez pas notre e-mail, regardez dans votre dossier Spam.
					</Alert>
					)}
					{message && (
						<Alert severity={messageType} style={{ justifyContent: 'center' }}>
							{message}
						</Alert>
					)}
					<form className={classes.form} noValidate method="POST" onSubmit={this.handleSubmit}>
						<TextField
							variant="outlined"
							margin="normal"
							required
							fullWidth
							label="Adresse e-mail"
							type="email"
							id="email"
							name="email"
							autoComplete="email"
							autoFocus
							value={email}
							onChange={this.handleChange}
						/>
						<Button
							type="submit"
							fullWidth
							variant="contained"
							color="primary"
							className={classes.submit}
							disabled={!this.validateForm()}
						>
							{!loading ? 'Envoyer ma demande' : <CircularProgress size={28} style={{ color: 'white' }} />}
						</Button>
						<Grid container>
							<Grid item xs>
								<Link href="/" variant="body2">
									Retourner au formulaire de connexion
								</Link>
							</Grid>
						</Grid>
					</form>
				</div>
				<Box mt={8}>
					<Copyright />
				</Box>
			</Container>
		);
	}
}

export default withStyles(useStyles)(ResettingComponent);
