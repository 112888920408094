import { authToken, env } from '../helper';
import { userService } from '../services';

export const catalogService = {
    getAll,
    getByValue,
    getByFilters
};

async function getAll(offset) {
    const response = await fetch(`${env.API_URL}/api/jeux/?offset=${offset}&limit=16`, {
        method: 'GET',
        headers: authToken(),
    }).then((response) => {
        if (!response.ok) {
            userService.logout();
            window.location.reload(true);
        }
        return response;
    });
    return await response.json();
}

async function getByValue(value) {
    const response = await fetch(`${env.API_URL}/api/jeux/recherche?value=${value}`, {
        method: 'GET',
        headers: authToken(),
    }).then((response) => {
        if (!response.ok) {
            userService.logout();
            window.location.reload(true);
        }
        return response;
    });
    return await response.json();
}

async function getByFilters(filters) {
    const response = await fetch(`${env.API_URL}/api/jeux/recherche-avancee`, {
        method: 'POST',
        headers: authToken(),
        body: JSON.stringify(filters)
    }).then((response) => {
        if (!response.ok) {
            userService.logout();
            window.location.reload(true);
        }
        return response;
    });
    return await response.json();
}