import React from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import './App.css';
import { PrivateRoute } from './helper';
import { Dashboard } from './layouts';
import {
	LoginComponent,
	NotFoundComponent,
	CatalogComponent,
	BookingsComponent,
	BookingsFormComponent,
	UserComponent,
	ResettingComponent,
	RegistrationComponent
} from './components';

function App() {
	return (
		<div className="App">
			<Router>
				<Switch>
					<Route exact path="/" component={LoginComponent} />
					<Route exact path="/resetting" component={ResettingComponent} />
					<Route exact path="/registration" component={RegistrationComponent} />
					<PrivateRoute exact path="/account" component={() => <Dashboard layout={CatalogComponent} />} />
					<PrivateRoute
						exact
						path="/account/bookings"
						component={() => <Dashboard layout={BookingsComponent} />}
					/>
					<PrivateRoute
						exact
						path="/account/bookings/:id"
						component={(props) => <Dashboard {...props} layout={BookingsFormComponent} />}
					/>
					<PrivateRoute exact path="/account/user" component={() => <Dashboard layout={UserComponent} />} />
					<Route path="/404" component={NotFoundComponent} />
					<Redirect to="/404" />
				</Switch>
			</Router>
		</div>
	);
}

export default App;
