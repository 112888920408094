import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { Button, Grid, Paper } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 350,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));

export default function Filter(props) {
    const classes = useStyles(),
        [state, setState] = React.useState({
            filters: props.filters,
            inputs: {
                type_objet_id: '',
                nbr_joueur_id: '',
                fournisseur_id: '',
                fabricant_id: '',
                structure_id: '',
                localisation_id: '',
                categorie_id: '',
                marque_id: '',
                duree_id: '',
                origine_id: '',
                lieu_id: '',
                typologie_id: '',
                mecanisme_id: '',
                convenance_id: ''
            }
        });

    const handleChange = (event) => {
        setState({
            ...state,
            inputs: {
                ...state.inputs,
                [event.target.name]: event.target.value
            }
        });
    };

    const handleSubmit = () => {
        let filters = {};
        for (const [key, value] of Object.entries(state.inputs)) {
            filters[key] = value;
        }
        filters = clean(filters);
        if (filters)
            props.onFilter(filters);
    };

    const clean = (o) => {
        for (var propName in o) {
            if (o[propName] === null || o[propName] === undefined || o[propName] === "") {
                delete o[propName];
            }
        }
        return o
    };

    return (
        <Paper variant="outlined" pb={5}>
            {state.filters.map((item, index) => (
                <FormControl key={`${index}`} className={classes.formControl}>
                    <InputLabel>{item.label}</InputLabel>
                    <Select
                        name={item.column}
                        value={state.inputs[item.column]}
                        onChange={handleChange}
                    >
                        <MenuItem key={0} value="">-- Aucune valeur --</MenuItem>
                        {Array.isArray(item.data) && item.data.length > 0 && item.data.map((option, index) => (
                            <MenuItem value={option.id} key={`${index}`}>
                                {option.value}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            ))}
            <Grid item md={12}>
                <Button
                    variant="contained"
                    color="secondary"
                    onClick={handleSubmit}
                    style={{ marginRight: '10px' }}
                    mb={5}
                >
                    Rechercher
                </Button>
            </Grid>
        </Paper>
    );
}